const LIVE_DATA = {
  upcoming: [
  ],
  past: [
    {
      educator: `Jeff Scheetz`,
      title: ``,
      time: `Thursday, January 18th, 2024 at 7:00 PM ET`,
      path: `jeff-scheetz-3`,
      id: 1091,
      active: true,
      img: 'images/live/jeff-scheetz-3/live_square_600.jpg'
    },
    {
      educator: `Jeff Scheetz`,
      title: ``,
      time: `Thursday, January 25th, 2024 at 7:00 PM ET`,
      path: `jeff-scheetz-4`,
      id: 1091,
      active: true,
      img: 'images/live/jeff-scheetz-3/live_square_600.jpg'
    },
    {
      educator: `Mimi Fox`,
      title: ``,
      time: `Tuesday, December 6th, 2022 at 3:00 PM ET`,
      path: `mimi-fox-2`,
      id: 3928,
      active: true
    },
    {
      educator: `Keb' Mo'`,
      title: ``,
      time: `Thursday, December 1st, 2022 at 3:00 PM ET`,
      path: `keb-mo`,
      id: 4677,
      active: true
    },
    {
      educator: 'Walter Trout',
      title: '',
      time:'Friday, March 11, 2022, 3:00pm ET',
      path: 'walter-trout',
      id: '4633',
      active: true
    },
    {
      educator: 'Greg Koch',
      title: '',
      time:'October 15th, 2021 at 3:00 PM ET',
      path: 'greg-koch',
      id: '3773',
      active: true
    },
    {
      educator: 'Universal Audio Live Event',
      title: '',
      time:'April 21st, 2021 at 6:00 PM ET',
      path: 'universal-audio',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/universal-audio/live2_square_600.jpg'
    },
    {
      educator: 'Maton HQ Live Event',
      title: '',
      time:'September 24th, 2020 at 7:00 PM ET',
      path: 'maton',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/maton/live3_square_600.jpg'
    },
    {
      educator: 'Songwriters & Storytellers Fireside Festival',
      title: '',
      time:'September 27th, 2020 at 5:00 PM ET',
      path: 'https://www.firesidefestivals.com/',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/songwriter-september/lineup/square_600_01.jpg'
    },
    {
      educator: 'TrueFire Live: Universal Audio with Corey Congilio',
      title: '',
      time:'July 23rd, 2020 at 5:00 PM ET',
      path: 'universal-audio-congilio',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/ua-congilio/live2_square_600@2x.jpg'
    },
    {
      educator: 'TrueFire Live: DIY Guitar Tech',
      title: '',
      time:'July 15th, 2020 at 4:00 PM ET',
      path: 'diy-guitar-tech',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/diy-guitar-tech/DIYGuitarTech_live_600@2x.png'
    },
    {
      educator: 'TrueFire Live: Two Rock Amplifiers',
      title: '',
      time:'June 9th, 2020 at 6:00 PM ET',
      path: 'two-rock',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/two-rock/live2_square_600@2x.jpg'
    },
    {
      educator: 'Fireside Festival II',
      title: '',
      time:'June 7th, 2020 at 4:00 PM ET',
      path: 'fireside-festival-2',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/fireside2/square_600@2x.jpg'
    },
    {
      educator: 'PRS Demo with Bryan Ewald',
      title: '',
      time:'May 29th, 2020 at 3:00 PM ET',
      path: 'prs-3',
      id: false,
      active: true,
      img: 'images/live/prs/live2_square_600.jpg'
    },
    {
      educator: 'Rob Garland & Andy Aledort',
      title: '',
      time:'May 28th, 2020 at 3:00 PM ET',
      path: 'garland-aledort',
      id: '40',
      active: true,
      multi: true
    },
    {
      educator: 'Ariane Cap & Andrew Ford',
      title: '',
      time:'May 26th, 2020 at 3:00 PM ET',
      path: 'cap-ford',
      id: '4365',
      active: true,
      multi: true
    },
    {
      educator: 'PRS Demo with Bryan Ewald',
      title: '',
      time:'May 22nd, 2020 at 3:00 PM ET',
      path: 'prs-2',
      id: false,
      active: true,
      img: 'images/live/prs/live2_square_600.jpg'
    },
    {
      educator: 'Sheryl Bailey & Jon Herington',
      title: '',
      time:'May 21st, 2020 at 3:00 PM ET',
      path: 'bailey-herington',
      id: '4198',
      active: true,
      multi: true
    },
    {
      educator: 'Andy Wood',
      title: '',
      time:'May 19th, 2020 at 3:00 PM ET',
      path: 'andy-wood-4',
      id: '4483',
      active: true,
      multi: false
    },
    {
      educator: 'Corey Congilio, David Grissom, & Tony McManus',
      title: '',
      time:'May 14th, 2020 at 3:00 PM ET',
      path: 'prs-grissom-congilio-mcmanus',
      id: '4326',
      active: true,
      img: 'images/live/prs2/live2_square_600.jpg'
    },
    {
      educator: 'Jason Loughlin',
      title: '',
      time:'May 12th, 2020 at 3:00 PM ET',
      path: 'jason-loughlin-5',
      id: '4214',
      active: true,
      multi: false
    },
    {
      educator: 'PRS Guitars',
      title: '',
      time:'May 8th , 2020at 3:00 PM ET',
      path: 'prs',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/prs/live2_square_600.jpg'
    },
    {
      educator: 'Muriel Anderson, Cathy Fink, Marcy Marxer',
      title: '',
      time:'May 7th, 2020 at 3:00 PM ET',
      path: 'anderson-fink-marxer',
      id: '161',
      active: true,
      multi: true
    },
    {
      educator: 'Rev Robert Jones & Jeff McErlain',
      title: '',
      time:'April 30th, 2020 at 3:00 PM ET',
      path: 'jones-mcerlain',
      id: '4486',
      active: true,
      multi: true
    },
    {
      educator: 'Fireside Festival',
      title: '',
      time:'May 1st, 2020 at 12:00 PM ET',
      path: 'fireside-festival',
      id: false,
      active: true,
      multi: false,
      img: 'images/live/fireside/square_600@2x.jpg'
    },
    {
      educator: 'Brad Carlton',
      title: '',
      time:'April 28th, 2020 at 3:00 PM ET',
      path: 'brad-carlton',
      id: '7',
      active: true,
      multi: false
    },
    {
      educator: 'Adam Levy, Mimi Fox, & Stu Hamm',
      title: '',
      time:'April 23rd, 2020 at 3:00 PM ET',
      path: 'levy-fox-hamm',
      id: '157',
      active: true,
      multi: true,
    },
    {
      educator: 'Laurence Juber',
      title: '',
      time:'April 21st, 2020 at 3:00 PM ET',
      path: 'laurence-juber',
      id: '4345',
      multi: true,
      active: true
    },
    {
      educator: 'Massimo Varini, Ariel Posen, & Vicki Genfan',
      title: '',
      time:'April 16th, 2020 at 3:00 PM ET',
      path: 'varini-posen-genfan',
      id: '4245',
      multi: true,
      active: true
    },
    {
      educator: 'Frank Vignola, Anita Camarella, & Davide Facchini',
      title: '',
      time:'April 14th, 2020 at 3:00 PM ET',
      path: 'vignola-anita-davide',
      id: '3424',
      multi: true,
      active: true
    },
    {
      educator: 'Josh Smith, Charlie Apicella, and Jennifer Batten',
      title: '',
      time:'April 9th, 2020 at 3:45 PM EST',
      path: 'smith-apicella-batten',
      id: '4498',
      multi: true,
      active: true
    },
    {
      educator: 'Tim Lerch & John Knowles',
      title: 'Live from the TrueFire Studio',
      time:'April 7th, 2020 at 3:00 PM EST',
      path: 'tim-lerch-john-knowles',
      id: '4539',
      multi: true,
      active: true
    },
    {
      educator: 'Matt Schofield',
      title: 'Live from the TrueFire Studio',
      time:'March 10th, 2020 at 3:00 PM EST',
      path: 'matt-schofield-3',
      id: '4494',
      active: true
    },
    {
      educator: 'Carter Arrington',
      title: 'Live from the TrueFire Studio',
      time:'March 5th, 2020 at 3:00 PM EST',
      path: 'carter-arrington',
      id: '4561',
      active: true
    },
    {
      educator: 'Robben Ford',
      title: 'Jazz Revolution',
      time:'Febraury 27th, 2020',
      path: 'robben-ford-3',
      id: '3776',
      active: true
    },
    {
      educator: 'Tim Lerch',
      title: 'Jazz Blues Pathways',
      time:'February 25th, 2020',
      path: 'tim-lerch-2',
      id: '4539',
      active: true
    },
    {
      educator: 'Sean McGowan',
      title: 'Live from the TrueFire Studio',
      time:'February 4th, 2020',
      path: 'sean-mcgowan-2',
      id: '4416',
      active: true
    },
    {
      educator: 'Jeff McErlain',
      title: 'Live from the TrueFire Studio',
      time:'January 30th, 2020',
      path: 'jeff-mcerlain-6',
      id: '224',
      active: true
    },
    {
      educator: 'Wolf Marshall',
      title: 'Live from the TrueFire Studio',
      time:'January 28th, 2020',
      path: 'wolf-marshall',
      id: '4530',
      active: true
    },
    {
      educator: 'Massimo Varini',
      title: 'Live from the TrueFire Studio',
      time:'January 21st, 2020',
      path: 'massimo-varini',
      id: '4245',
      active: true
    },
    {
      educator: 'Rob Garland',
      title: 'Live from the TrueFire Studio',
      time:'January 14th, 2020',
      path: 'rob-garland-5',
      id: '4238',
      active: true
    },
    {
      educator: 'Peter Mulvey',
      title: 'Live from the TrueFire Studio',
      time:'January 9th, 2020',
      path: 'peter-mulvey',
      id: '4523',
      active: true
    },
    {
      educator: 'Frank Vignola',
      title: 'Live from the TrueFire Studio',
      time:'January 7th, 2020',
      path: 'frank-vignola-6',
      id: '3424',
      active: true
    },
    {
      educator: 'Andy Wood',
      title: 'Live from the TrueFire Studio',
      time:'December 5th, 2019',
      path: 'andy-wood-3',
      id: '4483',
      active: true
    },
    {
      educator: 'Scott Sharrard',
      title: 'Live from the TrueFire Studio',
      time:'December 3rd, 2019',
      path: 'scott-sharrard-2',
      id: '4497',
      active: true
    },
    {
      educator: 'Clive Carroll',
      title: 'Live from the TrueFire Studio',
      time:'November 26th, 2019',
      path: 'clive-carroll',
      id: '4541',
      active: true
    },
    {
      educator: 'Gareth Pearson',
      title: 'Live from the TrueFire Studio',
      time:'November 19th, 2019',
      path: 'gareth-pearson',
      id: '4396',
      active: true
    },
    {
      educator: 'Steve Jenkins',
      title: 'Live from the TrueFire Studio',
      time:'November 14th, 2019',
      path: 'steve-jenkins',
      id: '4279',
      active: true
    },
    {
      educator: 'Corey Congilio',
      title: 'Live from the TrueFire Studio',
      time:'November 12th, 2019',
      path: 'corey-congilio-5',
      id: '4200',
      active: true
    },
    {
      educator: 'Greg Martin',
      title: 'Live from the TrueFire Studio',
      time:'November 7th, 2019',
      path: 'greg-martin',
      id: '64',
      active: true
    },
    {
      educator: 'Tim Lerch',
      title: 'Live from the TrueFire Studio',
      time:'November 5th, 2019',
      path: 'tim-lerch',
      id: '4539',
      active: true
    },
    {
      educator: 'Ariane Cap',
      title: 'Live from the TrueFire Studio',
      time:'October 29th, 2019',
      path: 'ariane-cap-5',
      id: '4365',
      active: true
    },
    {
      educator: 'David Henriksson',
      title: 'Live from the TrueFire Studio',
      time:'October 22nd, 2019',
      path: 'david-henriksson',
      id: '4554',
      active: true
    },
    {
      educator: 'Kid Andersen',
      title: 'Live from the TrueFire Studio',
      time:'October 17th, 2019',
      path: 'kid-andersen-2',
      id: '4496',
      active: true
    },
    {
      educator: 'John Knowles',
      title: 'Live from the TrueFire Studio',
      time:'October 15th, 2019',
      path: 'john-knowles',
      id: '4475',
      active: true
    },
    {
      educator: 'Bill Dickens',
      title: 'Live from the TrueFire Studio',
      time:'October 10th, 2019',
      path: 'bill-dickens',
      id: '4543',
      active: true
    },
    {
      educator: 'Robben Ford',
      title: 'Live from the TrueFire Studio',
      time:'October 3rd, 2019',
      path: 'robben-ford-2',
      id: '3776',
      active: true
    },
    {
      educator: 'Josh Smith',
      title: 'Live from the TrueFire Studio',
      time:'September 24th, 2019',
      path: 'josh-smith-2',
      id: '4498',
      active: true
    },
    {
      educator: 'Frank Vignola',
      title: 'Live from the TrueFire Studio',
      time:'September 19th, 2019',
      path: 'frank-vignola-5',
      id: '3424',
      active: true
    },
    {
      educator: 'Blair Dunlop',
      title: 'Live from the TrueFire Studio',
      time:'September 17th, 2019',
      path: 'blair-dunlop',
      id: '4520',
      active: true
    },
    {
      educator: 'Ryan Madora',
      title: 'Live from the TrueFire Studio',
      time:'September 12th, 2019',
      path: 'ryan-madora',
      id: '4540',
      active: true
    },
    {
      educator: 'Ariel Posen',
      title: 'Live from the TrueFire Studio',
      time:'September 10th, 2019',
      path: 'ariel-posen',
      id: '4544',
      active: true
    },
    {
      educator: 'Mark Whitfield',
      title: 'Live from the TrueFire Studio',
      time:'August 20th, 2019',
      path: 'mark-whitfield',
      id: '4505',
      active: true
    },
    {
      educator: 'Robert Jones',
      title: 'Live from the TrueFire Studio',
      time:'August 13th, 2019',
      path: 'rev-robert-jones-2',
      id: '4486',
      active: true
    },
    {
      educator: 'Andy Wood',
      title: 'Live from the TrueFire Studio',
      time:'August 1st, 2019',
      path: 'andy-wood-2',
      id: '4483',
      active: true
    },
    {
      educator: 'Adam Levy',
      title: 'Live from the TrueFire Studio',
      time:'July 25th, 2019',
      path: 'adam-levy',
      id: '157',
      active: true
    },
    {
      educator: 'Scott Sharrard',
      title: 'Live from the TrueFire Studio',
      time:'July 23rd, 2019',
      path: 'scott-sharrard',
      id: '4497',
      active: true
    },
    {
      educator: 'Jason Loughlin',
      title: 'Live from the TrueFire Studio',
      time:'July 16th, 2019',
      path: 'jason-loughlin-4',
      id: '4214',
      active: true
    },
    {
      educator: 'Mimi Fox',
      title: 'Jazz Song Practice',
      time:'July 11th, 2019',
      path: 'mimi-fox',
      id: '3928',
      active: true
    },
    {
      educator: 'Peter Mazza',
      title: 'Live from the TrueFire Studio',
      time:'June 26th, 2019',
      path: 'peter-mazza',
      id: '4503',
      active: true
    },
    {
      educator: 'Redd Volkaert',
      title: 'Redd Hot Guitar Licks',
      time:'June 20th, 2019',
      path: 'redd-volkaert',
      id: '4470',
      active: true
    },
    {
      educator: 'Corey Congilio',
      title: 'Bar Room Grooves',
      time:'June 13th, 2019',
      path: 'corey-congilio-4',
      id: '4200',
      active: true
    },
    {
      educator: 'Brooks Robertson',
      title: 'Live from the TrueFire Studio',
      time:'June 11th, 2019',
      path: 'brooks-robertson-3',
      id: '4304',
      active: true
    },
    {
      educator: 'Fareed Haque',
      title: 'Live from the TrueFire Studio',
      time:'June 6th, 2019',
      path: 'fareed-haque',
      id: '171',
      active: true
    },
    {
      educator: 'Andy Timmons',
      title: 'Live from the TrueFire Studio',
      time:'June 4th, 2019',
      path: 'andy-timmons',
      id: '4330',
      active: true
    },
    {
      educator: 'Matt Schofield',
      title: 'Playing the Changes',
      time:'May 30th, 2019',
      path: 'matt-schofield-2',
      id: '4494',
      active: true
    },
    {
      educator: 'Chris Buono',
      title: 'Sight Reading for Guitar',
      time:'May 22nd, 2019',
      path: 'chris-buono-4',
      id: '4184',
      active: true
    },
    {
      educator: 'Carl Verheyen',
      title: 'Live from the TrueFire Studio',
      time:'May 9th, 2019',
      path: 'carl-verheyen',
      id: '4223',
      active: true
    },
    {
      educator: 'Kid Andersen',
      title: 'Live from the TrueFire Studio',
      time:'May 7th, 2019',
      path: 'kid-andersen',
      id: '4496',
      active: true
    },
    {
      educator: 'Maurice Arenas ',
      title: 'Teaching + Q&A',
      time:'May 8th, 2019',
      path: 'maurice-arenas-3',
      id: '4230',
      active: true
    },
    {
      educator: 'Alex Skolnick',
      title: 'Live from the TrueFire Studio',
      time:'May 2nd, 2019',
      path: 'alex-skolnick-2',
      id: '16',
      active: true
    },
    {
      educator: 'Ariane Cap',
      title: 'Teaching + Q&A',
      time:'May 1st, 2019',
      path: 'ariane-cap-4',
      id: '4365',
      active: true
    },
    {
      educator: 'Ellis Paul',
      title: 'Live from the TrueFire Studio',
      time:'April 23rd, 2019',
      path: 'ellis-paul-2',
      id: '4469',
      active: true
    },
    {
      educator: 'Willy Porter',
      title: 'Live from the TrueFire Studio',
      time:'April 17th, 2019',
      path: 'willy-porter',
      id: '4525',
      active: true
    },
    {
      educator: 'Carl Burnett',
      title: 'Live from the TrueFire Studio',
      time:'April 4th, 2019',
      path: 'carl-burnett',
      id: '4363',
      active: true
    },
    {
      educator: 'Rob Garland',
      title: 'Live from the TrueFire Studio',
      time:'April 2nd, 2019',
      path: 'rob-garland-4',
      id: '4238',
      active: true
    },
    {
      educator: 'Sean McGowan',
      title: 'Live from the TrueFire Studio',
      time:'March 28th, 2019',
      path: 'sean-mcgowan',
      id: '4416',
      active: true
    },
    {
      educator: 'Ned Luberecki',
      title: 'Live from the TrueFire Studio',
      time:'March 20th, 2019',
      path: 'ned-luberecki-2',
      id: '4471',
      active: true
    },
    {
      educator: 'Jeff McErlain',
      title: 'Teaching + Q&A',
      time:'March 18th, 2019',
      path: 'jeff-mcerlain-5',
      id: '224',
      active: true
    },
    {
      educator: 'Rev. Robert Jones',
      title: 'Blues Traditions',
      time:'March 14th, 2019',
      path: 'rev-robert-jones',
      id: '4486',
      active: true
    },
    {
      educator: 'Corey Congilio',
      title: 'Teaching + Q&A',
      time:'March 11th, 2019',
      path: 'corey-congilio-3',
      id: '4200',
      active: true
    },
    {
      educator: 'Sheryl Bailey',
      title: 'Live from the TrueFire Studio',
      time:'March 7th, 2019',
      path: 'sheryl-bailey-2',
      id: '4198',
      active: true
    },
    {
      educator: 'Matthew Lee',
      title: 'Teaching + Q&A',
      time:'March 5th, 2019',
      path: 'matthew-lee',
      id: '4485',
      active: true
    },
    {
      educator: 'Robben Ford',
      title: 'Uptempo Blues',
      time:'February 26th, 2019',
      path: 'robben-ford',
      id: '3776',
      active: true
    },
    {
      educator: 'Kirk Fletcher',
      title: 'TrueHeart Blues: Rhythm',
      time:'February 21st, 2019',
      path: 'kirk-fletcher',
      id: '4509',
      active: true
    },
    {
      educator: 'Josh Smith',
      title: 'Blue Highways',
      time:'February 19th, 2019',
      path: 'josh-smith',
      id: '4498',
      active: true
    },
    {
      educator: 'Kelly Richey',
      title: 'Teaching + Q&A',
      time:'Febraury 11th, 2019',
      path: '/kelly-richey-3',
      id: '4280',
      active: true
    },
    {
      educator: 'Frank Vignola',
      title: 'Teaching + Q&A',
      time:'February 4th, 2019',
      path: 'frank-vignola-4',
      id: '3424',
      active: true
    },
    {
      educator: 'Stu Hamm',
      title: 'Live from the TrueFire Studio',
      time:'January 31st, 2019',
      path: 'stu-hamm',
      id: '4127',
      active: true
    },
    {
      educator: 'Luther Dickinson',
      title: 'Modern Mississippi Slide',
      time:'January 22nd, 2019',
      path: 'luther-dickinson',
      id: '4488',
      active: true
    },
    {
      educator: 'Jeff McErlain',
      title: 'Blues Guitar',
      time:'December 20th, 2018',
      path: 'jeff-mcerlain-4',
      id: '224',
      active: true
    },
    {
      educator: 'Henry Johnson',
      title: 'Live from the TrueFire Studio',
      time:'December 5th, 2018',
      path: 'henry-johnson',
      id: '4422',
      active: true
    },
    {
      educator: 'Thom Bresh',
      title: 'Live from the TrueFire Studio',
      time:'November 27th, 2018',
      path: 'thom-bresh',
      id: '3936',
      active: true
    },
    {
      educator: 'Jim Oblon',
      title: 'RetroACTIVE Electric Blues',
      time:'November 20th, 2018',
      path: 'jim-oblon',
      id: '4462',
      active: true
    },
    {
      educator: 'Ariane Cap',
      title: 'Bass Groove IQ',
      time:'November 15th, 2018',
      path: 'ariane-cap-2',
      id: '4365',
      active: true
    },
    {
      educator: 'Jay-P',
      title: 'Cutting-Edge R&B Blues Grooves',
      time:'November 11th, 2018',
      path: 'jay-p',
      id: '4425',
      active: true
    },
    {
      educator: 'Chris Buono',
      title: 'Live from the TrueFire Studio',
      time:'November 7th, 2018',
      path: 'chris-buono-3',
      id: '4184',
      active: true
    },
    {
      educator: 'Doug Munro',
      title: 'Django Inspiration',
      time:'October 18th, 2018',
      path: 'doug-munro-2',
      id: '4260',
      active: true
    },
    {
      educator: 'Alex Skolnick',
      title: 'Rocktoberfest',
      time:'October 11th, 2018',
      path: 'alex-skolnick',
      id: '16',
      active: true
    },
    {
      educator: 'Robbie Calvo',
      title: 'Rocktoberfest',
      time:'October 3rd, 2018',
      path: 'robbie-calvo-4',
      id: '4181',
      active: true
    },
    {
      educator: 'Corey Congilio',
      title: 'Modern Country Soloing',
      time:'September 25th, 2018',
      path: 'corey-congilio-2',
      id: '4200',
      active: true
    },
    {
      educator: 'Rick Vito',
      title: 'Slide Soulshaker',
      time:'September 25th, 2018',
      path: 'rick-vito',
      id: '4495',
      active: true
    },
    {
      educator: 'Matt Schofield',
      title: 'Blues Speak',
      time:'September 18th, 2018',
      path: 'matt-schofield',
      id: '4494',
      active: true
    },
    {
      educator: 'Andy Wood',
      title: 'Shape Shifter',
      time:'September 13th, 2018',
      path: 'andy-wood',
      id: '4483',
      active: true
    },
    {
      educator: 'Mike Zito',
      title: 'Blues Americana',
      time:'August 29th, 2018',
      path: 'mike-zito',
      id: '4324',
      active: true
    },
    {
      educator: 'Jeff McErlain',
      title: 'Blues Bends',
      time:'August 16th, 2018',
      path: 'jeff-mcerlain-3',
      id: '224',
      active: true
    },
    {
      educator: 'Ellis Paul',
      title: 'Songwriting',
      time:'August 14th, 2018',
      path: 'ellis-paul',
      id: '4469',
      active: true
    },
    {
      educator: 'Ned Luberecki',
      title: 'Bluegrass Banjo',
      time:'August 7th, 2018',
      path: 'ned-luberecki',
      id: '4471',
      active: true
    },
    {
      educator: 'Frank Vignola',
      title: 'Chord Melody',
      time:'July 19th, 2018',
      path: 'frank-vignola-3',
      id: '3424',
      active: true
    },
    {
      educator: 'Rob Garland',
      title: 'Melodic Soloing',
      time:'June 13th, 2018',
      path: 'rob-garland-3',
      id: '4238',
      active: true
    },
    {
      educator: 'Chris Buono',
      title: 'Guitar Gym Workouts',
      time:'June 7th, 2018',
      path: 'chris-buono-2',
      id: '4184',
      active: true
    },
    {
      educator: 'Jeff McErlain',
      title: 'Soloing the Changes',
      time:'May 1st, 2018',
      path: 'jeff-mcerlain-2',
      id: '224',
      active: true
    },
    {
      educator: 'Brooks Robertson',
      title: 'Boom-Chick Fingerstyle',
      time:'April 18th, 2018',
      path: 'brooks-robertson-2',
      id: '4304',
      active: true
    },
    {
      educator: 'Kelly Richey',
      title: 'Blues Grit',
      time:'April 10th, 2018',
      path: '/kelly-richey-2',
      id: '4280',
      active: true
    },
    {
      educator: 'Frank Vignola',
      title: 'Inversion Excursion',
      time:'March 6th, 2018',
      path: 'frank-vignola-2',
      id: '3424',
      active: true
    },
    {
      educator: 'Robbie Calvo',
      title: 'Sweet Notes',
      time:'February 7th, 2018',
      path: 'robbie-calvo-3',
      id: '4181',
      active: true
    },
    {
      educator: 'Jeff Scheetz',
      title: 'Smart Practice for Guitar',
      time:'January 31st, 2018',
      path: 'jeff-scheetz-2',
      id: '1091',
      active: true
    },
    {
      educator: 'Maurice Arenas ',
      title: 'Blues Alternatives',
      time:'January 10th, 2018',
      path: 'maurice-arenas',
      id: '4230',
      active: true
    },
    {
      educator: 'Tony Smotherman',
      title: 'Chord Voicings',
      time:'December 7th, 2017',
      path: 'tony-smotherman',
      id: '4210',
      active: true
    },
    {
      educator: 'Robbie Calvo',
      title: 'Lick Logic',
      time:'November 15th, 2017',
      path: 'robbie-calvo-2',
      id: '4181',
      active: true
    },
    {
      educator: 'Robert Renman',
      title: 'Triads are the Secret',
      time:'November 8th, 2017',
      path: 'robert-renman',
      id: '4325',
      active: true
    },
    {
      educator: 'Jon Finn',
      title: 'Improv Target Notes',
      time:'November 2nd, 2017',
      path: 'jon-finn',
      id: '41',
      active: true
    },
    {
      educator: 'Jamie Andreas',
      title: 'Building Speed and Power',
      time:'October 26th, 2017',
      path: 'jamie-andreas',
      id: '4231',
      active: true
    },
    {
      educator: 'Jason Loughlin',
      title: 'Country Soloing',
      time:'October 24th, 2017',
      path: 'jason-loughlin-2',
      id: '4214',
      active: true
    },
    {
      educator: 'Oz Noy',
      title: 'Creative Soloing',
      time:'October 19th, 2017',
      path: 'oz-noy',
      id: '3946',
      active: true
    },
    {
      educator: 'Robbie Calvo',
      title: 'Double Stops',
      time:'October 18th, 2017',
      path: 'robbie-calvo',
      id: '4181',
      active: true
    },
    {
      educator: 'Brooks Robertson',
      title: 'Harp Harmonics',
      time:'September 29th, 2017',
      path: 'brooks-robertson',
      id: '4304',
      active: true
    },
    {
      educator: 'Andy Aledort',
      title: 'Back to School Boot Camp',
      time:'August 16th, 2017',
      path: 'bts/andy-aledort',
      id: '40',
      active: true
    },
    {
      educator: 'Rob Garland',
      title: 'Back to School Boot Camp',
      time:'August 14th, 2017',
      path: 'bts/rob-garland',
      id: '4238',
      active: true
    },
    {
      educator: 'Robbie Laws',
      title: 'Back to School Boot Camp',
      time:'August 13th, 2017',
      path: 'bts/robbie-laws',
      id: '4381',
      active: true
    },
    {
      educator: 'Kelly Richey',
      title: 'Back to School Boot Camp',
      time:'August 12th, 2017',
      path: 'bts/kelly-richey',
      id: '4280',
      active: true
    },
    {
      educator: 'Jeff Scheetz',
      title: 'Back to School Boot Camp',
      time:'August 11th, 2017',
      path: 'bts/jeff-scheetz',
      id: '1091',
      active: true
    },
    {
      educator: 'Jeff McErlain',
      title: 'Back to School Boot Camp',
      time:'August 10th, 2017',
      path: 'bts/jeff-mcerlain',
      id: '224',
      active: true
    },
    {
      educator: 'Ariane Cap',
      title: 'Back to School Boot Camp',
      time:'August 9th, 2017',
      path: 'bts/ariane-cap',
      id: '4365',
      active: true
    },
    {
      educator: 'Frank Potenza',
      title: 'Back to School Boot Camp',
      time:'August 3rd, 2017',
      path: 'frank-potenza',
      id: '4378'
    },
    {
      educator: 'Tom Dempsey',
      title: 'Jazz Reharmonization',
      time:'August 1st, 2017',
      path: 'tom-dempsey',
      id: '4225'
    },
    {
      educator: 'Kelly Richey',
      title: '50 Licks Squared',
      time:'July 20th, 2017',
      path: 'kelly-richey',
      id: '4280'
    },
    {
      educator: 'Robbie Laws',
      title: 'Chord Comping for Blues',
      time:'July 18th, 2017',
      path: 'robbie-laws',
      id: '4381'
    },
    {
      educator: 'Chris Buono',
      title: 'Whammy Bar Bonanza',
      time:'June 29th, 2017',
      path: 'chris-buono',
      id: '4184'
    },
    {
      educator: 'Frank Vignola',
      title: 'Improvisation',
      time:'June 21st, 2017',
      path: 'frank-vignola',
      id: '3424'
    },
    {
      educator: 'Sheryl Bailey',
      title: 'Play the Changes',
      time:'June 8th, 2017',
      path: 'sheryl-bailey',
      id: '4198'
    },
    {
      educator: 'Ariane Cap',
      title: 'Spice up Your Practice',
      time:'June 1st, 2017',
      path: 'ariane-cap',
      id: '4365'
    },
    {
      educator: 'Doug Munro',
      title: 'Latin Guitar Explorations',
      time:'May 17th, 2017',
      path: 'doug-munro',
      id: '4260'
    },
    {
      educator: 'Rob Garland',
      title: 'Creating Beautiful Music',
      time:'May 11th, 2017',
      path: 'rob-garland',
      id: '4238'
    },
    {
      educator: 'Angus Clark',
      title: 'Prepping for a Gig',
      time:'April 26th, 2017',
      path: 'angus-clark',
      id: '4258'
    },
    {
      educator: 'Jason Loughlin',
      title: 'Country Flat Picking',
      time:'April 19th, 2017',
      path: 'jason-loughlin',
      id: '4214'
    },
    {
      educator: 'Corey Congilio',
      title: 'Soloing Without Licks',
      time:'April 5th, 2017',
      path: 'corey-congilio',
      id: '4200'
    },
    {
      educator: 'Jeff McErlain',
      title: 'Soloing Without Licks',
      time:'March 27th, 2017',
      path: 'jeff-mcerlain',
      id: '224'
    },
    {
      educator: 'Jeff Scheetz',
      title: 'Spice Up Your Blues-Rock Solos',
      time:'March 9th, 2017',
      path: 'jeff-scheetz',
      id: '1091'
    }
  ]
}

export const state = () => ({
  educators: {},
  auth_token: null
});

export const mutations = {
  setField(state, { id, field, value }) {
    if (!state.educators[id]) {
      state.educators[id] = {};
    }
    state.educators[id][field] = value;
    console.log(`Mutation setField: Set ${field} for educator ${id} to`, value);
  },
  updateEducator(state, { id, educator }) {
    if (!state.educators[id]) {
      state.educators[id] = {};
    }
    Object.assign(state.educators[id], educator);
    console.log(`Mutation updateEducator: Updated educator ${id} with`, educator);
  }
};

export const getters = {
  getField: (state) => (id, field) => {
    const educator = state.educators[id];
    //console.log(`Getter getField: Retrieved field ${field} for educator ${id}`, educator ? educator[field] : undefined);
    return educator && educator[field] ? educator[field] : undefined;
  },
  getEducator: (state) => (id) => {
    console.log(`Getter getEducator: Retrieved educator ${id}`, state.educators[id]);
    return state.educators[id] || {};
  }
};

function safeJSONParse(str) {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error('JSON parsing error:', error);
    return {};
  }
}

async function extractFieldsFromObject(fields, object) {
  const results = {};
  fields.forEach(field => {
    results[field] = object[field] !== undefined ? object[field] : null;
  });
  //console.log(`Function extractFieldsFromObject: Extracted fields`, results);
  return results;
}

export const actions = {
  async getFields({ commit, state }, { id, fields, $axios, $cookies }) {
    //console.log('Action getFields: Started', { id, fields });
    const fieldsArray = Array.isArray(fields) ? fields : [fields];

    // Check for data in cookies first
    let educator = state.educators[id] || {};
    const cookieData = $cookies.get(`educator-${id}-data`);
    if (cookieData) {
      const parsedCookieData = safeJSONParse(cookieData);
      //console.log('Action getFields: Parsed cookie data', parsedCookieData);
      educator = { ...educator, ...parsedCookieData };
      //console.log('Action getFields: Merged educator data with cookie data', educator);
      commit('updateEducator', { id, educator });
    }

    // Extract available fields from the merged educator data
    let extractedFields = await extractFieldsFromObject(fieldsArray, educator);
    //console.log('Action getFields: Extracted fields from cookie and state', extractedFields);

    // Check for missing fields
    const missingFields = fieldsArray.filter(field => !(field in extractedFields && extractedFields[field] != null));
    //console.log('Action getFields: Missing fields after checking cookie and state', missingFields);

    if (missingFields.length === 0) {
      //console.log('Action getFields: All fields found locally or in cookie', extractedFields);
      return typeof fields === 'string' ? extractedFields[fields] : extractedFields;
    }

    try {
      const response = await $axios.post(process.env.nexusUrl, { educator: { id, fields: missingFields, no_cache: true } });

      if (response.status === 200 && response.data.educator) {
        commit('updateEducator', { id, educator: response.data.educator });
        console.log('my fields array', fieldsArray)
        for(let i = 0; i < fieldsArray.length; i++)
          if(fieldsArray[i].includes(':')) fieldsArray[i] = fieldsArray[i].substring(0, fieldsArray[i].indexOf(':'))
        extractedFields = await extractFieldsFromObject(fieldsArray, response.data.educator);


        if(extractedFields && LIVE_DATA){
          if(LIVE_DATA.upcoming && (LIVE_DATA.upcoming.length)){
            for(let i = 0; i < LIVE_DATA.upcoming.length; i++){
              if(parseInt(LIVE_DATA.upcoming[i].id) == parseInt(id)){
                if(!extractedFields.live) extractedFields.live = {}
                if(!extractedFields.live.upcoming) extractedFields.live.upcoming = []
                extractedFields.live.upcoming.push(LIVE_DATA.upcoming[i])
              }
            }
          }
          if(LIVE_DATA.past && (LIVE_DATA.past.length)){
            for(let i = 0; i < LIVE_DATA.past.length; i++){
              if(parseInt(LIVE_DATA.past[i].id) == parseInt(id)){
                if(!extractedFields.live) extractedFields.live = {}
                if(!extractedFields.live.past) extractedFields.live.past = []
                console.log()
                extractedFields.live.past.push(LIVE_DATA.past[i])
              }
            }
          }
        }

        $cookies.set(`educator-${id}-data`, JSON.stringify(extractedFields), {
          path: '/',
          domain: '.truefire.com',
          maxAge: 60 * 60 * 24 // 24 hours
        });


        //console.log('Action getFields: Updated fields after fetch', extractedFields);
        return typeof fields === 'string' ? extractedFields[fields] : extractedFields;
      } else {
        console.error('Action getFields: Non-200 status code received:', response.status);
      }
    } catch (error) {
      console.error('Action getFields: Error fetching data:', error);
    }
    return typeof fields === 'string' ? null : {};  // Return appropriate type based on fields input
  },

  async subscribeToEmails({ commit, state }, { fields, $axios, }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, fields);
      return response;
    } catch(err) {
      console.error('Error submitting email...', err);
    }
  }
};
