// plugins/tfs-utils.js
/* global phpajax */
export default (context, inject) => {
    const { $axios, store, $cookies } = context;

    const tfsUtils = {
        check_domain(url) {
            return true;
        },

        log(message, color = 'blue') {
            console.log(`%c${message}`, `color: ${color}; font-weight: bold;`);
        },

        gtm_push(gtmData) {
            window.dataLayer = window.dataLayer || [];
            if (Array.isArray(gtmData)) {
                gtmData.forEach((data) => {
                    window.dataLayer.push(data);
                });
            } else {
                window.dataLayer.push(gtmData);
            }
        },

        setStoreValue(mutation, token) {
            if (!store || typeof store.commit !== 'function') {
                console.error('Vuex store is not available');
                return;
            }
            // Committing to the 'setToken' mutation in the 'auth' module
            store.commit(mutation, token);
        },
        getStoreValue(state) {
            if (!store || typeof store.state !== 'object') {
                console.error('Vuex store is not available');
                return;
            }
            return store.state[state];
        },

        action_push(actionData) {
            // Implement the action push logic if needed
        },

        addCookieToHeader(config, cookieName, headerName) {
            //check the store for the token
            if (store.state.member.authToken) {
                config.headers[headerName] = store.state.member.authToken;
            }
            if (tfsUtils.check_domain(config.url)) {
                let cookieData = $cookies.get(cookieName) || localStorage.getItem(cookieName);
                if (cookieData) {
                    config.headers[headerName] = cookieData;
                }
            }
        },

        doGTM() {
            function doPHPAjax() {
                if (typeof phpajax === "undefined") return;

                if (phpajax.gtm_data) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ meta: phpajax.gtm_data });
                }
            }

            function loadGTM(containerId) {
                if (isGTMContainerLoaded(containerId)) return;
                if (!document || !document.head) return; // Safety check

                const scriptElement = document.createElement("script");
                scriptElement.defer = true;
                scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${containerId}`;
                document.head.appendChild(scriptElement);
                startGtmContainer(containerId);
                tfsUtils.log(`Loaded ContainerId: ${containerId}`);
                window.dataLayer = window.dataLayer || [];
                doPHPAjax();
            }

            function startGtmContainer(containerId) {
                if (!document || !document.body) return; // Safety check

                const existingIframe = document.querySelector(
                    'iframe[src="https://www.googletagmanager.com/ns.html?id=' + containerId + '"]'
                );
                if (!existingIframe) {
                    const iframe = document.createElement("iframe");
                    iframe.src = "https://www.googletagmanager.com/ns.html?id=" + containerId;
                    iframe.height = 0;
                    iframe.width = 0;
                    iframe.style.display = "none";
                    document.body.appendChild(iframe);
                }
            }

            function isGTMContainerLoaded(containerId) {
                let test;
                try {
                    test = typeof window.google_tag_manager[containerId];
                } catch (error) {
                    return false; // Consider revising this to handle errors more appropriately
                }
                return test;
            }

            if (!window.location.search.includes("nogtm=1")) {
                const containers = [];
                if (location.host.includes("localhost")) containers.push("GTM-TJL3Q67");
                if (location.host.includes("truefire.com") && !location.href.includes('partner-nonce')) containers.push("GTM-WKLPZW9"); // truefire main
                if (location.host.includes("truefire.com") && !location.href.includes('partner-nonce'))containers.push("GTM-NH5QB3NN"); // third party tracking scripts
                if (location.host.includes("truefiregiveaways.com")) containers.push("GTM-WC4ZS8B");
                if (location.host.includes("jamplay.com")) containers.push("GTM-9MNC");

                // Load each GTM container for the current host.
                containers.forEach(function (container) {
                    try {
                        loadGTM(container);
                    } catch (error) {
                        console.error(error);
                    }
                });
            }
        },

        doAxios() {
            function configureAxios() {
                $axios.interceptors.request.use((config) => {
                    config.headers["Content-Type"] = "application/json";
                    config.headers["Accept"] = "application/json";
                    // config.headers["Access-Control-Allow-Origin"] = "*";
                    // config.headers["Access-Control-Allow-Headers"] = "*";

                    tfsUtils.addCookieToHeader(config, "auth_token", "tf-token");
                    tfsUtils.addCookieToHeader(config, "server_auth_token", "tf-token");
                    tfsUtils.addCookieToHeader(config, "tfs_auth_token", "tf-token");
                    tfsUtils.addCookieToHeader(config, "partner_id", "partner-id");
                    tfsUtils.addCookieToHeader(config, "hubspotutk", "hubspotutk");
                    tfsUtils.addCookieToHeader(config, "plus_network_id", "plus-network-id");

                    return config;
                });

                $axios.interceptors.response.use(response => {
                    handleGtmPush(response);
                    handleCookiePush(response);
                    setAuthenticationToken(response);
                    handleActionPush(response);
                    return response;
                }, error => {
                    return Promise.reject(error);
                });
            }

            function handleGtmPush(response) {
                if (response.headers && response.headers["gtm-push"]) {
                    try {
                        const gtmData = JSON.parse(atob(response.headers["gtm-push"]));
                        tfsUtils.gtm_push(gtmData);
                    } catch (error) {
                        console.error("Error parsing GTM data", error);
                    }
                }
            }

            function handleCookiePush(response) {
                if (response.headers && response.headers["cookie-push"]) {
                    try {
                        const cookieData = JSON.parse(atob(response.headers["cookie-push"]));
                        console.log("COOKIE DATA", cookieData);
                        window.dataLayer = window.dataLayer || [];
                        if (cookieData.length) {
                            cookieData.forEach((data) => {
                                const $expires = data.expires ? calculateDaysFromDate(data.expires) : 1;
                                if ($expires < 1) {
                                    $cookies.remove(data.name);
                                    localStorage.removeItem(data.name);
                                } else {
                                    let maxAge = $expires * 24 * 60 * 60;
                                    $cookies.set(data.name, data.value, { maxAge: maxAge, domain: getRootDomain(), path: '/' });
                                    localStorage.setItem(data.name, data.value);
                                }
                            });
                        }
                    } catch (error) {
                        console.error("Error parsing cookie data", error);
                    }
                }
            }

            function setAuthenticationToken(response) {
                if (response.headers && response.headers["tf-token"]) {
                    console.log("TF-TOKEN FOUND");
                    try {
                        const tfToken = response.headers["tf-token"];
                        console.log("TF-TOKEN DATA", tfToken);
                        tfsUtils.setStoreValue('member/setAuthToken', tfToken);
                    } catch (error) {
                        console.error("Error parsing TF-TOKEN", error);
                    }
                }
            }

            function handleActionPush(response) {
                if (response.headers && response.headers["actions-push"]) {
                    try {
                        const actionData = JSON.parse(atob(response.headers["actions-push"]));

                        if (actionData.length) {
                            actionData.forEach((data) => {
                                if (data.method === 'redirect_uri') {
                                    actionRedirect(data.arguments);
                                } else if(data.method == 'show_pin_form'){
                                    console.log('hello setting', data.arguments)
                                    tfsUtils.setStoreValue('member/setPinForm', data.arguments)
                                }
                            });
                        }
                    } catch (error) {
                        console.error("Error parsing actions data", error);
                    }
                }
            }

            function actionRedirect(args) {
                console.log('actionRedirect', args);
                if (args.url) {
                    window.location.href = args.url;
                }
            }

            function calculateDaysFromDate(targetDateStr) {
                const targetDate = new Date(targetDateStr);
                const currentDate = new Date();
                const timeDiff = targetDate.getTime() - currentDate.getTime();
                const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
                return daysDiff;
            }

            function getRootDomain() {
                const domainParts = window.location.hostname.split(".");
                if (domainParts.length > 2) {
                    domainParts.shift();
                }
                return domainParts.join(".");
            }

            configureAxios();
        },
    };

    if (process.client) {
        // Early data layer initialization
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'js-init'
        });

        tfsUtils.doAxios();
        tfsUtils.doGTM();
    }

    inject('tfsUtils', tfsUtils);
};
