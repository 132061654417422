export const state = () => ({
  configs: {},
  nav: {},
  plusNetworkId: null
});

export const mutations = {
  setById(state, { id, data }) {
    state.configs[id] = data;
  },
  setNav(state, data) {
    state.nav = data;
  },
  setPlusNetworkId(state, id) {
    console.log('attempting to set plus network id', id);
    state.plusNetworkId = id;
  }
};

export const getters = {
  getById: (state) => (id) => {
    const config = state.configs[id];
    return config ? { ...config, source: 'vuex' } : undefined;
  }
};

// Helper functions for data storage and retrieval
function safeJSONParse(str) {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error('JSON parsing error:', error);
    return null;
  }
}


function setStorage(key, data) {
  console.log('storing data', data);
  const timestampedData = {
    data,
    timestamp: new Date().getTime()
  };
  const stringifiedData = JSON.stringify(timestampedData);
  if (process.client) {
    localStorage.setItem(key, stringifiedData);
  }
}

function getStorage(key) {
  if (process.client && localStorage.getItem(key)) {
    const storedData = safeJSONParse(localStorage.getItem(key));
    if (storedData && storedData.timestamp) {
      const currentTime = new Date().getTime();
      const oneHour = 60 * 60 * 1000;

      if (currentTime - storedData.timestamp < oneHour) {
        return storedData.data;
      } else {
        // Data has expired, remove it from localStorage
        localStorage.removeItem(key);
        return null;
      }
    }
  }
  return null;
}

export const actions = {
  async fetchConfigById({ commit, state }, { id, $axios, $cookies }) {
    // Check if the config is already in the Vuex store
    if (state.configs[id]) {
      return { ...state.configs[id], source: 'vuex' };
    }

    // Check if the config is in localStorage
    const localConfig = getStorage(id);
    if (localConfig) {
      commit('setById', { id, data: localConfig });
      return { ...localConfig, source: 'local' };
    }

    // Check if the config is in cookies
    let cookieConfig = $cookies.get(id);
    if (cookieConfig) {
      cookieConfig = safeJSONParse(cookieConfig);
      if (cookieConfig) {
        commit('setById', { id, data: cookieConfig });
        return { ...cookieConfig, source: 'cookie' };
      }
    }

    try {
      const response = await $axios.get(`${process.env.configsUrl || '/tfs/api/configs'}/${id}`);
      const dataWithSource = { ...response.data, source: 'api' };

      // Cache the response in localStorage
      if (process.client) {
        setStorage(id, dataWithSource);
      }

      commit('setById', { id, data: dataWithSource });
      return dataWithSource;
    } catch (error) {
      console.error('Failed to fetch config data:', error);
      throw error;
    }
  },
  async getNav({ commit, state }, { type, $axios, $cookies }) {
    const id = state.plusNetworkId;
    try {
      const response = await $axios.get(`${process.env.tfsApiUrl || '/tfs/api/'}nav/${type}/${id}`);
      commit('setNav', response.data);
      return response.data;
    } catch(e) {
     // $cookies.remove('plus_network_id');
      return false;
    }
  },

  async getPlusNetwork({ commit, state }, { $axios, $cookies }) {
      return state.plusNetworkId;
  }
};